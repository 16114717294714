@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 30 100% 57%;
    --primary: 214 99% 57%;
    --primary-foreground: 0 0% 100%;
    --secondary: 213 96% 11%;
    --secondary-foreground: 0 0% 100%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 216 100% 98%;
    --accent-foreground: 240 10% 3.9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 213 96% 11%;
    --secondary-foreground: 0 0% 100%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
  }
}

nav .sidebar-list li:first-child,
nav .sidebar-list li:nth-child(4),
nav .sidebar-list li:nth-child(8) {
  margin-bottom: 2rem;
}

.select-scroll {
  max-height: 200px; /* يمكنك ضبط هذا القيمة حسب الحاجة */
  overflow-y: auto;
}


nav .sidebar-list li:nth-child(9) {
  margin-bottom: 8rem;
}

nav
  .sidebar-list
  li:not(
    :last-child,
    :nth-child(9),
    :first-child,
    :nth-child(4),
    :nth-child(8)
  ) {
  margin-bottom: 0.5rem;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(var(--primary) / 0.5);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: hsl(var(--primary-foreground) / 0.3);
}


html {
  font-size: 16px;
}

body {
  font-family: 'Poppins', sans-serif;
}

.custom-scroll::-webkit-scrollbar {
  height: 5px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.custom-scroll:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
